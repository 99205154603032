import React from 'react';
import PropTypes from 'prop-types';
import localize from '@src/i18n';
import HeartCheckIcon from '@comp/Icons/HeartCheckIcon';
import sDefault from './HealthBenefit.scss';
import sRedesign from './HealthBenefitRedesign.scss';

const HealthBenefit = ({ isRedesign }) => {
  const s = isRedesign ? sRedesign : sDefault;
  return (
    <p className={s.healthBenefit}>
      {isRedesign && <HeartCheckIcon className={s.icon} />}
      <span>{localize('health_benefit')}</span>
    </p>
  );
};

HealthBenefit.propTypes = {
  isRedesign: PropTypes.bool
};

export default HealthBenefit;
